import { DataGrid } from "@mui/x-data-grid";
import LightTooltip from "../dataset/LightTooltip";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  row: {
    maxHeight: "unset !important",
    minHeight: "auto !important",
  },
  cellComment: {
    height: "auto !important",
    maxHeight: "unset !important",
    minHeight: "auto !important",
  },
  text: {
    overflow: "visible",
    whiteSpace: "normal",
  },
});

function DetailTable({ data, classes }) {
  const columns = [
    {
      field: "documentTitle",
      headerName: "Título de documento",
      cellClassName: classes.cellComment,
      flex: 1,
      renderCell: ({ row }) => (
        <span>
          <p className={classes.text}>{row.documentTitle}</p>
        </span>
      ),
    },
    {
      field: "commentedOrganizationAbbreviation",
      headerName: "Org que comenta",
      cellClassName: classes.cellComment,
      width: 155,
      renderCell: ({ row }) => (
        <span>
          <LightTooltip title={row.commentedOrganization ?? ""} arrow>
            <p className={classes.text}>
              {row.commentedOrganizationAbbreviation}
            </p>
          </LightTooltip>
        </span>
      ),
    },
    {
      field: "comment",
      headerName: "Comentarios",
      cellClassName: classes.cellComment,
      flex: 1,
      minWidth: 300,
      renderCell: ({ row }) => (
        <span>
          <p className={classes.text}>{row.comment}</p>
        </span>
      ),
    },
  ];

  return (
    <div style={{ height: "535px", width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        getRowClassName={() => classes.row}
      />
    </div>
  );
}

export default withStyles(styles)(DetailTable);
