import React, { PureComponent } from "react";
//import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ReactEcharts from "echarts-for-react";
import { Link } from "react-router-dom";
import ElearningAppBar from "../components/ElearningAppBar";
import OrganizacionesService from "../services/organizacionservice";
import snackbarStore from "../stores/snackbar-store";

const useStyles = makeStyles((theme) => ({
  layout: {
    //width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
      theme.spacing.unit * 3
    }px`,
  },
  textfield: {
    width: "100%",
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    textAlign: "center",
  },
  txtp1: {
    textDecoration: "none",
    color: "#000000",
    fontWeight: "400",
    fontSize: "12px",
  },
  tables: {
    marginLeft: "0",
    paddingLeft: "0",
  },
  orgInfo: {
    fontSize: "12px",
    marginBottom: "10px",
    color: "#00529c",
  },
  paperBread: {
    width: "auto",
    display: "inline-block",
    fontSize: "16px",
    textDecoration: "none",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
  },
  inputS: {
    borderRadius: "10px",
    marginLeft: "5px",
    marginTop: "0px",
    display: "inline-block",
    backgroundColor: "#FFFFFF",
  },
  contenedorEstadistica: {
    borderRadius: "10px",
    border: "2px solid #E3E3E3",
    padding: "10px 10px 10px 10px",
  },
  contenedorEstadisticaTexto: {
    borderRadius: "10px",
    border: "2px solid #E3E3E3",
    padding: "10px 10px 10px 10px",
    textAlign: "center",
    height: "140px",
    color: "#00529c",
  },
  paperBreadContainer: {
    display: "flex",
  },
  filtros: {
    display: "inline-block",
    marginTop: "0px",
    marginLeft: "auto",
    marginRight: "0px",
    borderRadius: "20px",
  },
}));

const PagePorSexo = () => {
  const option = {
    tooltip: {},
    legend: {
      data: ["Al menos 1 capacitación", "Más de 1 capacitación"],
      left: "left",
    },
    xAxis: {
      data: ["Femenino", "Masculino"],
    },
    yAxis: {},
    series: [
      {
        name: "Al menos 1 capacitación",
        type: "bar",
        data: [0, 0],
        color: "#7359C5",
      },
      {
        name: "Más de 1 capacitación",
        type: "bar",
        data: [0, 0],
        color: "#2AB7B3",
      },
    ],
  };
  return <ReactEcharts option={option} style={{ height: 300 }} />;
};

const PageRangoEtario = () => {
  const option = {
    tooltip: {},
    legend: {
      data: ["Al menos 1 capacitación", "Más de 1 capacitación"],
      left: "left",
    },
    xAxis: {
      data: ["<30", "30 a 50", ">50"],
    },
    yAxis: {},
    series: [
      {
        name: "Al menos 1 capacitación",
        type: "bar",
        data: [0, 0, 0],
        axisTick: {
          alignWithLabel: true,
        },
        color: "#7359C5",
      },
      {
        name: "Más de 1 capacitación",
        type: "bar",
        data: [0, 0, 0],
        color: "#2AB7B3",
      },
    ],
  };
  return <ReactEcharts option={option} style={{ height: 300 }} />;
};

class Pie extends PureComponent {
  getOption = () => ({
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "horizontal",
      left: "center",
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        data: [
          { value: 0, name: "Sector Público" },
          { value: 0, name: "Sector Privado" },
        ],
        color: ["#177ABB", "#FB9D3B", "#FA3D53"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  });

  render() {
    return (
      <ReactEcharts
        option={this.getOption()}
        style={{ height: "300px", width: "100%" }}
      />
    );
  }
}

function EstadisticasElearning(props) {
  const [organizaciones, setOrganizaciones] = React.useState([]);
  const [desde, setDesde] = React.useState();
  const [hasta, setHasta] = React.useState();

  const [organizacionCantidad, setOrganizacionCantidad] = React.useState();

  React.useEffect(() => {
    OrganizacionesService.getAllBasicNoParticular()
      .then((response) => {
        setOrganizaciones(response.data);
      })
      .catch((e) => {});
  }, []);

  React.useEffect(() => {
    OrganizacionesService.getCantidad()
      .then((response) => {
        setOrganizacionCantidad(response.data);
      })
      .catch((e) => {});
  }, []);

  const styles = useStyles();

  return (
    <React.Fragment>
      <ElearningAppBar>
        <main className={styles.layout}>
          <div className={styles.paperBreadContainer}>
            <Breadcrumbs aria-label="breadcrumb" className={styles.paperBread}>
              <a className={styles.txtlink} href="https://cursos.cnfc.gov.py/">
                E-Learning
              </a>
              <Link className={styles.txtlink} to={props.match.url}>
                <strong>Estadísticas</strong>
              </Link>
            </Breadcrumbs>

            <div className={styles.filtros}>
              <TextField
                className={styles.inputS}
                variant="outlined"
                id="date"
                type="date"
                size="small"
                margin="dense"
                label="Desde"
                onChange={(event) => {
                  var fechaD = new Date(event.target.value);
                  fechaD.setDate(fechaD.getDate() + 1);
                  fechaD < hasta
                    ? setDesde(fechaD)
                    : hasta === undefined
                    ? setDesde(fechaD)
                    : snackbarStore.openSnackbar(
                        "Especifique una fecha anterior",
                        "error"
                      );
                }}
                sx={{ width: 200 }}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={styles.inputS}
                variant="outlined"
                id="date"
                type="date"
                size="small"
                margin="dense"
                label="hasta"
                onChange={(event) => {
                  var fechaH = new Date(event.target.value);
                  fechaH.setDate(fechaH.getDate() + 1);
                  fechaH > desde
                    ? setHasta(fechaH)
                    : desde === undefined
                    ? setHasta(fechaH)
                    : snackbarStore.openSnackbar(
                        "Especifique una fecha posterior",
                        "error"
                      );
                }}
                sx={{ width: 200 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <Paper className={styles.paper}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <div className={styles.contenedorEstadistica}>
                    <h4>Personas capacitadas por sector</h4>
                    <Pie />
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <div className={styles.contenedorEstadistica}>
                    <h4>Personas capacitadas por sexo</h4>
                    <PagePorSexo />
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <div className={styles.contenedorEstadistica}>
                    <h4>Personas capacitadas por rango etario</h4>
                    <PageRangoEtario />
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>{{ ...organizacionCantidad }[0]}</h1>
                    <h3>Instituciones Públicas</h3>
                  </div>
                </Grid>

                <Grid item xs={12} md={4} lg={2}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>{{ ...organizacionCantidad }[1]}</h1>
                    <h3>Instituciones Privadas</h3>
                  </div>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>0</h1>
                    <h3>Cursos publicados</h3>
                  </div>
                </Grid>

                <Grid item xs={12} md={4} lg={2}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>0</h1>
                    <h3>Personas Capacitadas</h3>
                  </div>
                </Grid>

                <Grid item xs={12} md={4} lg={2}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>0</h1>
                    <h3>Personas Capacitadas más de 1 curso</h3>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </main>
      </ElearningAppBar>
    </React.Fragment>
  );
}

export default EstadisticasElearning;
