import React from "react";
import { Route } from "react-router-dom";
import EstadisticasElearning from "../components/EstadisticasElearning";
import Estadisticas from "../components/estadisticas/Estadisticas";
import AuthService from "../services/auth-service";
import Activar from "./Activar";
import Auth from "./Login";
import IEAuth from "./IELogin";
import NuevaContrasena from "./NuevaContrasena";
import RecuperacionDeCuenta from "./RecuperacionDeCuenta";
import RegistroUsuarioCiudadano from "./RegistroCiudadano";
import RegistroUsuarioCiudadanoAdmin from "./RegistroUsuarioCiudadano";
import RegUserInst from "./RegistroUsuarioInstitucional";
import AboutRoute from "./about/AboutRoute";
import CrearSubirDoc from "./crearSubirDocumento/NewDoc";
import SubirVersion from "./crearSubirDocumento/NewVers";
import Actividad from "./dataset/Actividad";
import Comentar from "./dataset/Comentar";
import DataRoute from "./dataset/DataRoute";
import Comentarios from "./dataset/DatasetComentarios";
import Gestionar from "./dataset/DatasetGestionarDocumentos";
import Moderar from "./dataset/DatasetModerarComentario";
import Historico from "./dataset/DatasetHistorico";
import Reportes from "./dataset/DatasetReporte";
import Organizaciones from "./dataset/DatasetOrganizaciones";
import TipoActividad from "./dataset/DatasetTipoActividad";
import TipoDocumento from "./dataset/DatasetTipoDocumento";
import Usuarios from "./dataset/DatasetUsuarios";
import DatasetVigente from "./dataset/DatasetVigentes";
import Datos from "./dataset/Datos";
import Descargas from "./dataset/Descargas";
import Diccionario from "./dataset/Diccionario";
import Rubro from "./dataset/Rubro";
import estadotipoactividad from "./estadotipoactividad";
import estaodusuario from "./estadousuario";
import LegalRoute from "./legal/LegalRoute";
import MiPerfil from "./miPerfil/MiPerfil";
import DatasetGestionarArchivos from "./misarchivos/DatasetGestionarArchivos";
import NuevoArchivo from "./misarchivos/NuevoArchivo.jsx";
import editarTipoActividad from "./modals/editarTipoActividad";
import editarUsuarioInstitucional from "./modals/editarUsuarioInstitucional";
import nuevoTipoActividad from "./modals/nuevoTipoActividad";
import Visualizaciones from "./visualizaciones/Visualizaciones";
import MenuPage from "./menuPageClickLogo/menuPage";
import editarUsuarioCiudadano from "./modals/editarUsuarioCiudadano.js";

const user = AuthService.getCurrentUser();

const AuthenticatedApp = () => (
  <React.Fragment>
    <Route
      exact
      path="/"
      component={
        !user
          ? DataRoute
          : user != null && user.rol.nombre === "Administrador"
          ? Usuarios
          : user != null && user.rol.nombre === "Institución"
          ? Gestionar
          : DataRoute
      }
    />
    <Route
      exact
      path="/inicio"
      component={
        !user
          ? DataRoute
          : user != null && user.rol.nombre === "Administrador"
          ? Usuarios
          : user != null && user.rol.nombre === "Institución"
          ? Gestionar
          : DataRoute
      }
    />
    <Route exact path="/visualizaciones" component={Visualizaciones} />
    <Route exact path="/legal" component={LegalRoute} />
    <Route exact path="/about" component={AboutRoute} />
    <Route exact path="/:dataset/datos" component={Datos} />
    <Route exact path="/comentarios/:idDocumento" component={Comentarios} />
    <Route exact path="/moderar" component={Moderar} />
    <Route exact path="/historico" component={Historico} />
    <Route exact path="/vigentes" component={DatasetVigente} />
    <Route exact path="/reportes/:idDocumento" component={Reportes} />
    <Route exact path="/gestionar" component={Gestionar} />
    <Route exact path="/usuarios" component={Usuarios} />
    <Route exact path="/estadousuario/:idUsuario" component={estaodusuario} />
    <Route exact path="/menu-page" component={MenuPage} />
    <Route
      exact
      path="/estadotipoactividad/:idTipoActividad"
      component={estadotipoactividad}
    />
    <Route exact path="/organizaciones" component={Organizaciones} />
    <Route exact path="/tipoactividad" component={TipoActividad} />
    <Route exact path="/tipoDocumento" component={TipoDocumento} />
    <Route exact path="/:dataset/diccionario" component={Diccionario} />
    <Route exact path="/:dataset/descargas" component={Descargas} />
    <Route exact path="/actividades/:empresaId" component={Actividad} />
    <Route exact path="/rubros/:empresaId" component={Rubro} />
    <Route exact path="/comentar/:idDocumento" component={Comentar} />
    <Route
      exact
      path="/recuperaciondecuenta/"
      component={RecuperacionDeCuenta}
    />
    <Route
      exact
      path="/nuevacontrasena/:userHash/"
      component={NuevaContrasena}
    />
    <Route
      exact
      path="/nuevacontrasena/:userHash/:moodleId"
      component={NuevaContrasena}
    />
    <Route exact path="/login/" component={Auth} />
    <Route exact path="/activar/:userHash" component={Activar} />
    <Route exact path="/login/:urlAnterior" component={Auth} />
    <Route exact path="/login/mitic/" component={IEAuth} />
    <Route
      exact
      path="/registro/ciudadano"
      component={RegistroUsuarioCiudadano}
    />
    <Route exact path="/NuevoUsuarioInstitucional" component={RegUserInst} />
    <Route exact path="/NuevoUsuarioCiudadano" component={RegistroUsuarioCiudadanoAdmin} />
    <Route
      exact
      path="/EditarUsuarioInstitucional/:idUsuario"
      component={editarUsuarioInstitucional}
    />
    <Route exact path="/EditarUsuarioCiudadano/:idUsuario" component={editarUsuarioCiudadano} />
    <Route exact path="/miperfil" component={MiPerfil} />
    <Route exact path="/subirdocumento" component={CrearSubirDoc} />
    <Route exact path="/subirversion/:idDocumento" component={SubirVersion} />
    <Route exact path="/nuevoTipoActividad" component={nuevoTipoActividad} />
    <Route
      exact
      path="/editActivity/:idActividad"
      component={editarTipoActividad}
    />
    <Route exact path="/estadisticas" component={Estadisticas} />
    <Route exact path="/elearning" component={EstadisticasElearning} />
    <Route exact path="/archivos" component={DatasetGestionarArchivos} />
    <Route exact path="/crear_archivo" component={NuevoArchivo} />
    <Route
      exact
      path="/archivos/:idArchivo/crear_version"
      component={NuevoArchivo}
    />
  </React.Fragment>
);

export default AuthenticatedApp;
