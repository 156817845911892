import { FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import cnfc from "../images/solologocnfc.png";
import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";
import { axiosCreateUserElearning } from "../utils/axiosElearning";
import {
  getFistNameByFullName,
  getLastNameByFullName,
  getUsernameByEmail,
} from "../utils/form-data";

const denormalize = (data) => {
  const target = {
    nombre_apellido: data.nombre_apellido || "",
    email: data.email || "",
    tipo_documento: data.tipo_documento || "",
    nro_documento: data.nro_documento || "",
    telefono: data.telefono || "",
    contrasena: data.contrasena || "",
    tipoOrganizacion: data.tipoOrganizacion || "",
    organizacion: data.organizacion?.id || "",
  };

  return target;
};

const denormalizeElearning = (data) => {
  const target = {
    username: getUsernameByEmail(data.email || ""),
    password: data.contrasena || "",
    email: data.email || "",
    email2: data.email || "",
    firstname: getFistNameByFullName(data.nombre_apellido || ""),
    lastname: getLastNameByFullName(data.nombre_apellido || ""),
    city: "",
    country: "PY",
  };

  return target;
};

//para tipo de documento personal del usuario
const tipoDocumentos = ["CI", "RUC"];

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  subtittle: {
    textAlign: "center",
  },
  registrarse: {
    textAlign: "left",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "6px",
  },
  radioContainer: {
    marginTop: "10px",
  },
  txttitulo: {
    textAlign: "center",
    color: "#0E1820",
    fontSize: "20px",
  },
});

function RegistroCiudadano(props) {
  const history = useHistory();
  const [org, setOrg] = React.useState([]);

  const getOrgByType = (type) => {
    axios
      .get(`organizaciones/type/${type}`, {
        data: {},
      })
      .then((resp) => setOrg(resp.data))
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado organizaciones",
          "error"
        );
      });
  };

  const handleTypeOrgChange = (_, type) => {
    formik.setFieldValue("tipoOrganizacion", type);
    getOrgByType(type);
  };

  const handleClickAcceptTerms = () => {
    if (!formik.values.acceptTerms) {
      snackbarStore.openSnackbar(
        "Debe aceptar los Términos y condiciones",
        "warning"
      );
    }
  };
  const { classes } = props;
  const [validado, setValidado] = React.useState(false);
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const formik = useFormik({
    initialValues: {
      nombre_apellido: "",
      email: "",
      tipo_documento: "",
      nro_documento: "",
      tipoOrganizacion: "Privada",
      organizacion: null,
      telefono: "",
      contrasena: "",
      repeatPassword: "",
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      nombre_apellido: Yup.string().required(
        "El nombre y apellido es requerido"
      ),
      email: Yup.string()
        .email("Debe ser un email valido")
        .required("El email es requerido"),
      tipoOrganizacion: Yup.string(),
      organizacion: Yup.object()
        .nullable()
        .when("tipoOrganizacion", {
          is: "Privada",
          then: Yup.object()
            .nullable()
            .required("La organizacion es requerida"),
        })
        .when("tipoOrganizacion", {
          is: "Pública",
          then: Yup.object()
            .nullable()
            .required("La organizacion es requerida"),
        }),
      contrasena: Yup.string()
        .required("La contraseña es requerida")
        // .min(8, "La contraseña debe tener al menos 8 caracteres")
        .oneOf([Yup.ref("repeatPassword")], "Las Contraseñas no son iguales"),
      repeatPassword: Yup.string()
        .required("La confirmación de contraseña es requerida")
        .oneOf([Yup.ref("contrasena")], "Las Contraseñas no son iguales"),
      acceptTerms: Yup.bool().oneOf(
        [true],
        "Debe aceptar los Términos y Condiciones"
      ),
    }),
    onSubmit: (formdata, { setSubmitting }) => {
      const user = denormalizeElearning(formdata);
      const payloadElearning = new FormData();
      payloadElearning.append("users[0][username]", user.username);
      payloadElearning.append("users[0][password]", user.password);
      payloadElearning.append("users[0][email]", user.email);
      payloadElearning.append("users[0][firstname]", user.firstname);
      payloadElearning.append("users[0][lastname]", user.lastname);

      axiosCreateUserElearning
        .post("", payloadElearning, { "Content-Type": "application/json" })
        .then((resp) => resp.data)
        .then((resp) => {
          if (resp.debuginfo || resp.errorcode) {
            const errorCode = resp.debuginfo || resp.errorcode;
            const errorMessage =
              errorCode === "invalidparameter"
                ? "El usuario con este correo ya existe en el Elearning"
                : errorCode;
            snackbarStore.openSnackbar(errorMessage, "error", 60000);
          } else {
            const payload = denormalize(formdata);
            payload.moodleid = resp[0].id;
            // /* OBS: Petición para crear usuarios en la BD del Gestor Documental removida, ahora solo se necesita crear usuarios en el Moodle */
            // axios
            //   .post("usuarios/registro", payload, {
            //     "Content-Type": "application/json",
            //   })
            //   .then(() => {
            //     setValidado(true);
            //   });
            setValidado(true);
            snackbarStore.openSnackbar(
              "Su cuenta a sido creada con éxito",
              "success"
            );
            history.replace("/");
            window.location.reload();
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  if (validado) {
    snackbarStore.openSnackbar("Su cuenta a sido creada con éxito", "success");
    history.replace("/");
    window.location.reload();
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    getOrgByType("Privada");
  }, []);

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <a href="https://www.cnfc.gov.py">
            <img width="350px" src={cnfc} alt="CNFC" />
          </a>
          <div style={{ width: "100%" }}>
            <LinearProgress color="primary" hidden={!formik.isSubmitting} />
            <h2 className={classes.txttitulo}> Crear cuenta</h2>
          </div>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Nombre y Apellido"
              value={formik.values.nombre_apellido}
              onChange={formik.handleChange}
              error={formik.errors.nombre_apellido && true}
              helperText={formik.errors.nombre_apellido}
              id="nombre_apellido"
              name="nombre_apellido"
              required
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email && true}
              helperText={formik.errors.email}
              id="email"
              name="email"
              required
            />

            <FormControl
              margin="dense"
              fullWidth
              variant="outlined"
              className={classes.inputS}
            >
              <Autocomplete
                id="tipo_documento"
                size="small"
                options={tipoDocumentos}
                value={formik.values.tipo_documento}
                onChange={(_, newTipodoc) => {
                  formik.setFieldValue("tipo_documento", newTipodoc);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //value={formik.values.tipo_documento}
                    onChange={formik.handleChange}
                    className={classes.inputS}
                    variant="outlined"
                    id="tipo_documento"
                    name="tipo_documento"
                    label="Tipo Documento"
                    error={formik.errors.tipo_documento && true}
                    helperText={formik.errors.tipo_documento}
                  />
                )}
              />
            </FormControl>

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Nro documento"
              value={formik.values.nro_documento}
              onChange={formik.handleChange}
              error={formik.errors.nro_documento && true}
              helperText={formik.errors.nro_documento}
              id="nro_documento"
              name="nro_documento"
            />

            <FormControl
              component="fieldset"
              className={classes.radioContainer}
            >
              <FormLabel component="legend">Tipo de Organizacion</FormLabel>
              <RadioGroup
                row
                aria-label="tipoOrganizacion"
                name="tipoOrganizacion"
                value={formik.values.tipoOrganizacion}
                onChange={handleTypeOrgChange}
              >
                <FormControlLabel
                  value="Privada"
                  control={<Radio color="default" />}
                  label="Privada"
                />
                <FormControlLabel
                  value="Pública"
                  control={<Radio color="default" />}
                  label="Pública"
                />
              </RadioGroup>
            </FormControl>

            <FormControl
              margin="dense"
              fullWidth
              variant="outlined"
              className={classes.inputS}
            >
              <Autocomplete
                id="organizacion"
                size="small"
                options={org}
                value={formik.values.organizacion}
                onChange={(_, newOrganizacion) => {
                  formik.setFieldValue("organizacion", newOrganizacion);
                }}
                getOptionLabel={(option) => option.abreviatura}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formik.values.organizacion}
                    onChange={formik.handleChange}
                    variant="outlined"
                    id="organizacion"
                    name="organizacion"
                    label="Organizacion"
                    error={formik.errors.organizacion && true}
                    helperText={formik.errors.organizacion}
                    required
                  />
                )}
              />
            </FormControl>

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              label="Teléfono"
              error={formik.errors.telefono && true}
              helperText={formik.errors.telefono}
              id="telefono"
              name="telefono"
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.contrasena}
              onChange={formik.handleChange}
              label="Contraseña"
              error={formik.errors.contrasena && true}
              helperText={formik.errors.contrasena}
              id="contrasena"
              name="contrasena"
              type={values.showPassword ? "text" : "password"}
              autoComplete="current-password"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              label="Confirmar contraseña"
              error={formik.errors.repeatPassword && true}
              helperText={formik.errors.repeatPassword}
              id="repeatPassword"
              name="repeatPassword"
              type={values.showPassword ? "text" : "password"}
              autoComplete="current-password"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              //value="acepto"
              control={
                <Checkbox
                  value={formik.values.acceptTerms}
                  onChange={formik.handleChange}
                  error={formik.errors.acceptTerms}
                  color="primary"
                  id="acceptTerms"
                  name="acceptTerms"
                />
              }
              label="Acepto los términos y condiciones"
              labelPlacement="end"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              onClick={handleClickAcceptTerms}
            >
              CREAR
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(RegistroCiudadano);
